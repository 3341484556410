<template>
	<div class="container flex f-column f-align">
		<span class="font60 black bold">管理员登录</span>
		<div class="flex f-align code-box mt40" @click="clickHandle($event, 'account')">
			账号
			<input ref="account" class="code-input" placeholder="请输入" v-model="formData.account" />
		</div>
		<div class="flex f-align code-box" @click="clickHandle($event, 'password')">
			密码
			<input class="code-input" placeholder="请输入" v-model="formData.password" />
		</div>
		<button class="next-btn" @click="nextHandle">确定</button>

		<key-board ref="keyBoard" @change="boardChange" :max="max" @complete="nextHandle">
		</key-board>
	</div>
</template>

<script>
	import keyBoard from '../components/keyBoard.vue'
	export default {
		data() {
			return {
				formData: {
					account: '',
					password: '',
				},
				passwordModel: '',
				passType: 'password',
				inputType: '',
				max: 20
			}
		},
		mounted() {
			this.$emit('change', '')
			setTimeout(() => {
				this.$refs.account.focus()
				this.inputType = 'account'
				this.$refs.keyBoard.focusIndex = 0
				this.$refs.keyBoard.commonStr = this.formData.account
			}, 300)
		},
		components: {
			keyBoard
		},
		methods: {
			toPass() {
				var val = this.formData.password; //取到输入框的值
				if (val.length > this.passwordModel.length) {
					//输入值
					this.passwordModel += val.charAt(val.length - 1);
				} else {
					//回删值
					this.passwordModel = this.passwordModel.substr(0, val.length);
				}
				//将输入框除最后一位的字符替换成*
				this.formData.password = val.substr(0, val.length - 1).replace(/./g, "*") + val.charAt(val.length - 1);
				/* 取当前输入框长度，用于判断是否正在输入 停止输入时，一秒后将最后一个字符变成*号 */
				var len = this.formData.password.length;
				setTimeout(() => {
					/*这时的this指向在延迟器触发时输入框的状态，而不是延迟器创建时的状态*/
					if (this.formData.password.length == len) {
						//一秒后输入框的值长度不变，将所有字符替换为*
						this.formData.password = this.formData.password.replace(/./g, "*");
					}
				}, 1000);
			},
			boardChange(str) {
				this.formData[this.inputType] = str
				if (this.inputType == 'password') {
					this.toPass()
				}
			},
			nextHandle() {
				if (!this.formData.account) {
					this.$toast("请输入账号");
					return
				}
				if (!this.passwordModel && !this.formData.password) {
					this.$toast("请输入密码");
					return
				}

				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					target: '.app-box'
				});

				this.$post("/Admin/gzbhLogin", {
					account: this.formData.account,
					password: this.passwordModel || this.formData.password,
					gzbh: localStorage.getItem('gzbh')
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						localStorage.setItem('adminId', res.admin.id)
						this.$router.replace("/adminList");
					} else {
						this.$toast({
							message: res.msg,
							duration: 3000
						});
					}
				}).catch(() => {
					loading.close()
				})
			},
			clickHandle(event, type) {
				if (type == 'account') {
					this.max = 20
				} else {
					this.max = 20
				}

				this.inputType = type
				this.$refs.keyBoard.focusIndex = event.target.selectionStart
				this.$refs.keyBoard.commonStr = this.formData[type]
			}
		}
	}
</script>

<style scoped lang="scss">
	.container {
		padding-top: 32px;

		.code-box {
			width: 600px;
			height: 80px;
			background: rgba(235, 235, 235, 1);
			border: 1px solid #777777;
			border-radius: 10px;
			padding-left: 30px;
			margin-bottom: 30px;
			font-size: 36px;
			color: #777777;
			line-height: 36px;
		}

		.code-input {
			background: none;
			border: none;
			box-sizing: border-box;
			padding-left: 30px;
			font-size: 36px;
			color: #000000;
			line-height: 36px;
			width: 400px;
		}

		.next-btn {
			width: 250px;
			height: 80px;
			background: rgba(64, 130, 253, 1);
			border-radius: 6px;
			line-height: 80px;
			text-align: center;
			font-size: 30px;
			color: #ffffff;
			margin-bottom: 20px;
		}
	}
</style>
