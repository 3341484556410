<template>
	<div class="f-center w100">
		<div v-if="keyType == 'text'" class="w100">
			<div class="key-board" v-if="boardType == 'large'">
				<div class="flex">
					<p @click="enter(item)" class="keys" v-for="item in boardData_l.arr1" :key="item">{{ item }}</p>
				</div>
				<div class="flex">
					<p @click="enter(item)" class="keys" v-for="item in boardData_l.arr2" :key="item">{{ item }}</p>
				</div>
				<div class="flex">
					<p @click="enter(item)" class="keys" v-for="item in boardData_l.arr3" :key="item">{{ item }}</p>
				</div>
				<div class="flex">
					<p @click="enter(item)" class="keys" v-for="item in boardData_l.arr4" :key="item">{{ item }}</p>
				</div>
				<div class="s_l" @click="changeHandle('small')">切换小写</div>
			</div>
			<div class="key-board" v-if="boardType == 'small'">
				<div class="flex">
					<p @click="enter(item)" class="keys" v-for="item in boardData_s.arr1" :key="item">{{ item }}</p>
				</div>
				<div class="flex">
					<p @click="enter(item)" class="keys" v-for="item in boardData_s.arr2" :key="item">{{ item }}</p>
				</div>
				<div class="flex">
					<p @click="enter(item)" class="keys" v-for="item in boardData_s.arr3" :key="item">{{ item }}</p>
				</div>
				<div class="flex">
					<p @click="enter(item)" class="keys" v-for="item in boardData_s.arr4" :key="item">{{ item }}</p>
				</div>
				<div class="s_l" @click="changeHandle('large')">切换大写</div>
			</div>
		</div>
		<div class="key-board-num" v-if="keyType == 'num'">
			<p @click="enter(item)" class="keys-num" v-for="(item, index) in boardNumArr" :key="index">{{ item }}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			keyType: {
				type: String,
				default: 'text'
			},
			max: {
				type: Number,
				default: 11
			},
			boardType: {
				type: String,
				default: 'small'
			}
		},
		data() {
			return {
				boardData_s: {
					arr1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "删除"],
					arr2: ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
					arr3: ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
					arr4: ["z", "x", "c", "v", "b", "n", "m", "确定"]
				},
				boardData_l: {
					arr1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "删除"],
					arr2: ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
					arr3: ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
					arr4: ["Z", "X", "C", "V", "B", "N", "M", "确定"]
				},
				boardNumArr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "删除"],
				focusIndex: 0,
				commonStr: ''
			}
		},
		methods: {
			changeHandle(type) {
				this.boardType = type
			},
			enter(item) {
				let arr
				if (item == '删除') {
					if (this.focusIndex > 0) {
						arr = this.commonStr.split("")
						arr.splice(this.focusIndex - 1, 1)
						this.commonStr = arr.join("")
						this.focusIndex--
						this.$emit('change', this.commonStr)
					}
				} else if(item == '确定') {
					this.$emit('complete')
				} else {
					arr = this.commonStr.split("")
					if (arr.length < this.max) {
						arr.splice(this.focusIndex, 0, item)
						this.commonStr = arr.join("")
						this.focusIndex++
						this.$emit('change', this.commonStr)
					}
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.key-board-num {
		display: flex;
		flex-wrap: wrap;
		
		.keys-num {
			width: 80px;
			height: 78px;
			background: #ffffff;
			border-radius: 10px;
			border: 1px solid #92909D;
			line-height: 78px;
			text-align: center;
			font-size: 30px;
			color: #333333;
			margin: 0 2px;
		}
	}
	
	.key-board {
		width: 100%;
		height: 294px;
		background: rgba(64, 130, 253, 0.39);
		border-radius: 0px 0px 30px 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		justify-content: center;
		
		.keys {
			width: 66px;
			height: 60px;
			border-radius: 5px;
			font-size: 24px;
			line-height: 60px;
			text-align: center;
			background-color: #FFFFFF;
			margin: 3px 3px;
		}
		.s_l {
			position: absolute;
			bottom: 20px;
			left: 20px;
			font-size: 24px;
			line-height: 30px;
			background-color: #FFFFFF;
			padding: 10px;
		}
	}
</style>
